<template>
  <nesi-user-common>
    <template #query>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="区域">
          <el-select v-model="form.districtId" placeholder="请选择区域">
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.areaList"
              :key="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="租赁方式">
          <el-select v-model="form.rentType" placeholder="请选择租赁方式">
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.rentTypeList"
              :key="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="月租金">
          <el-select v-model="form.rentPrice" placeholder="请选择月租金">
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.priceList"
              :key="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="户型">
          <el-select v-model="form.roomNum" placeholder="请选择室数" style='width: 130px'>
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.layoutList"
              :key="item.value"
            />
          </el-select>
          <el-select v-model="form.hallNum" placeholder="请选择厅数" style='width: 130px;margin-left: 6px'>
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.hallList"
              :key="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="装修">
          <el-select v-model="form.situation" placeholder="请选择装修情况">
            <el-option
              :label="item.label"
              :value="item.value==='0'?'':item.value"
              v-for="item in constant.situationList"
              :key="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="求租小区" style="width: 293px;">
          <el-input v-model="form.projectName" placeholder="请输入小区关键字" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="primary" plain @click="onRealese"
            >求租发布</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <template #content>
      <el-table
        v-loading='loading'
        :element-loading-text="loadingText"
        :data="tableData"
        :show-header='false'
        style="width: 100%"

        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f5f7fa'
        }"
      >
        <el-table-column >
          <template #default="{row}">
            求
            <el-link
              type='primary'
              :underline='false'
            >{{ row.title }}
            </el-link>
            的房源
          </template>
        </el-table-column>
        <el-table-column width='200px'>
          <template #default="{row}">
            租金范围：<span style='color: #fd1e26'
          >{{ row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column width='160px'>
          <template #default="{row}">
          <span class='name'>{{ row.name }}</span
          >：
            <el-button
              type='primary'
              plain
              size='mini'
              @click='callPhone(row)'
            >拨打号码
            </el-button>
          </template>
        </el-table-column>
        <el-table-column width='180px'>
          <template #default="{row}">
            入住时间：{{ row.iDate }}
          </template>
        </el-table-column>
        <el-table-column width='180px'>
          <template #default="{row}">
            发布时间：{{ row.pDate }}
          </template>
        </el-table-column>

<!--        <el-table-column prop="communityName" label="小区名称" />
        <el-table-column prop="locationName" label="城区" width="80" />
        <el-table-column prop="layout" label="房型" width="120" />
        <el-table-column prop="rentTypeCn" label="出租方式" width="80" />
        <el-table-column prop="situationName" label="装修" width="100" />
        <el-table-column prop="price" label="租金范围" width="130" />
        <el-table-column prop="name" label="联系人" width="130" />
        <el-table-column prop="iDate" label="入住时间" width="120" />
        <el-table-column prop="pDate" label="发布时间" width="120" />
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              class="list-btn"
              @click="callPhone(scope.row)"
              >拨打电话
            </el-button>
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        @current-change="currentChange"
        :total="total"
        :page-size="form.pageSize"
        style="text-align: right; margin: 10px; 0"
      />
    </template>
  </nesi-user-common>
</template>
<script setup>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import {
  AREA_MAP,
  RENT_TYPE_MAP,
  PRICE_MAP,
  LAYOUT_MAP,
  SITUATIONS_MAP,
  HALL_MAP
} from '@/constant'
import { deepCopy } from '@/utils/util'
import { getVirtualPhoneApi, searchRentInfo } from '@/api/home'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'

import router from '../../router'
import { ElMessage, ElMessageBox } from 'element-plus'

defineComponent({
  NesiUserCommon
})

const total = ref(0)

const constant = reactive({
  areaList: deepCopy(AREA_MAP),
  rentTypeList: deepCopy(RENT_TYPE_MAP),
  priceList: deepCopy(PRICE_MAP),
  layoutList: deepCopy(LAYOUT_MAP),
  situationList: deepCopy(SITUATIONS_MAP),
  hallList: deepCopy(HALL_MAP)
})
const loading = ref(false)
const loadingText = ref('数据加载中...')
const form = reactive({
  districtId: '',
  pageNo: 1,
  pageSize: 10,
  projectName: '',
  rentPrice: '',
  rentType: '',
  hallNum: '',
  roomNum: '',
  situation: ''
})

const tableData = ref([])
const getRentList = async () => {
  try {
    loadingText.value = '数据加载中...'
    loading.value = true
    const resp = await searchRentInfo(form)
    total.value = resp.total
    tableData.value = resp.records.map(item => {
      const rentTypeCn = item.rentType === 0 ? '不限' : item.rentType === 2 ? '分租' : '整租'
      const bean = {
        title: `${item.locationName ? item.locationName : '不限区'} | ${item.communityName} | ${item.roomNum}室${item.hallNum}厅${item.toiletNum}卫 | ${rentTypeCn} | ${item.situationName ? item.situationName : '其他'}`,
        price: item.rentPrice && item.rentPrice != '0' ? item.rentPrice.split('_').join('-') + '元/月' : '不限',
        name: item.propertyRightName,
        iDate: item.upAt.split(' ')[0],
        pDate: item.updatedAt.split(' ')[0],
        id: item.id,
        userId: item.userId
      }
      if (item.rentPrice === '0_800') {
        bean.price = '800元以内/月'
      }
      if (item.rentPrice === '5000_') {
        bean.price = '5000元以上/月'
      }
      return bean
      /* return {
        communityName: item.communityName,
        locationName: item.locationName,
        layout: `${item.roomNum}室${item.hallNum}厅${item.toiletNum}卫`,
        rentTypeCn: rentTypeCn,
        situationName: item.situationName ? item.situationName : '--',
        price: item.rentPrice.split('_').join('-'),
        name: item.propertyRightName,
        iDate: item.upAt.split(' ')[0],
        pDate: item.updatedAt.split(' ')[0],
        id: item.id,
        userId: item.userId
      } */
    })
    console.log(resp)
  } finally {
    loading.value = false
  }
}
// 拨打虚拟电话
const callPhone = async (item) => {
  try {
    loadingText.value = '正在获取联系号码...'
    loading.value = true
    const result = await getVirtualPhoneApi(item.userId)
    ElMessageBox.alert(`请手机拨打联系人号码：${result}`, '拨打提示', {
      confirmButtonText: '确认',
      callback: (action) => {
      }
    })
    /* const { errorCode, telX, errorMsg } = result
    if (errorCode === 0 && telX) {
      ElMessageBox.alert(`请手机拨打联系人号码：${telX}`, '拨打提示', {
        confirmButtonText: '确认',
        callback: (action) => {

        }
      })
    } else {
      ElMessage.error(errorMsg)
    } */
  } finally {
    loading.value = false
  }
}
const currentChange = value => {
  form.pageNo = value
  getRentList()
}
onMounted(() => {
  getRentList()
})
const onSubmit = () => {
  form.pageNo = 1
  getRentList()
}
const onRealese = () => {
  router.push('/user/release')
}
</script>
<style lang="sass"></style>
